import React, { useEffect, useState, useRef } from "react";
import {useParams} from 'react-router-dom';
import Header from './Header'
import Footer from './Footer'
import {Helmet} from "react-helmet";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import WidgetTable from "./WidgetTable";
const WidgeDetails = () => {
  const scriptRef = useRef(null);
const {newsId} = useParams();
const [key, setKey] = useState('home');
  useEffect(() => {
    // Ensure Entity_sport is declared BEFORE loading the script
    window.Entity_sport = [
      {
        code: "4654436544",
        field: "entity_cricket",
        widget_type: "content_type",
        widget: "competetion_standings",
        id: "129413",
        more_one: "",
        widget_size: "large",
        where_to: "whereUwantToPutOnlyIdcompetetion_standings",
        base_path: "https://dashboard.entitysport.com/widget/dashboard/example/",
        links: "0",
        color_type: "light",
        choosed_color: "",
        choosed_preset: "",
      },
      {
        code: "4654436544",
        field: "entity_cricket",
        widget_type: "content_type",
        widget: "match_center",
        id: newsId ? newsId :87700,
        more_one: "",
        widget_size: "large",
        where_to: "whereUwantToPutOnlyIdmatch_center",
        base_path: "https://dashboard.entitysport.com/widget/dashboard/example/",
        links: "0",
        color_type: "light",
        choosed_color: "",
        choosed_preset: "",
      },
      {
        code: "4654436544",
        field: "entity_cricket",
        widget_type: "content_type",
        widget: "series_top_pages",
        id: "129413",
        more_one: "batting_most_runs",
        widget_size: "large",
        where_to: "whereUwantToPutOnlyIdseries_top_pages",
        base_path: "https://dashboard.entitysport.com/widget/dashboard/example/",
        links: "0",
        color_type: "light",
        choosed_color: "",
        choosed_preset: "",
      },
      {
        code: "4654436544",
        field: "entity_cricket",
        widget_type: "content_type",
        widget: "series_top_pages",
        id: "129413",
        more_one: "bowling_top_wicket_takers",
        widget_size: "large",
        where_to: "whereUwantToPutOnlyIdTopWickets",
        base_path: "https://dashboard.entitysport.com/widget/dashboard/example/",
        links: "0",
        color_type: "light",
        choosed_color: "",
        choosed_preset: "",
      }
    ];

    // Function to modify match links after widgets load
    const modifyLinks = () => {
      document
        .querySelectorAll(".entity-cricket-match-row[data-mid]")
        .forEach((el) => {
          const mid = el.getAttribute("data-mid");
          if (mid) {
            el.setAttribute("href", `/ipl/${mid}`);
          }
        });
    };

    // Load widget.js script if not already loaded
    if (!document.querySelector('script[src="https://dashboard.entitysport.com/widget/assets/js/widget.js"]')) {
      const script = document.createElement("script");
      script.src = "https://dashboard.entitysport.com/widget/assets/js/widget.js";
      script.defer = true;
      script.onload = modifyLinks; // Modify links after script loads
      document.body.appendChild(script);
      scriptRef.current = script;
    } else {
      modifyLinks(); // If script is already loaded, run the function directly
    }

    // Observe dynamically added elements and modify links
    const observer = new MutationObserver(modifyLinks);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect(); // Stop observing changes when component unmounts
    };
  }, [key]);

  return (
    <>
            <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <title>IANS LIVE</title>
            <link rel="icon" href="icon-new.ico" type="image/x-icon" />
            <link rel="shortcut icon" href="favicon.ico" type="image/x-icon" />
            <meta name="keywords" content='' />
            <meta name="description" content="ians live " />
            <meta property="og:title" content='IANS LIVE'/>
            <meta property="og:description" content="ians live " />
            <meta property="og:image" content="/assets/Images/logo-ianslive.png" />
            <meta property="og:url" content="https://ianslive.in" />
            <meta property="og:type" content="article" />  
        </Helmet>
        <div id="wrapper" className="wrapper">
            <div id="main_content" className="footer-fixed">
                <Header />
                  <main>
                      <div className="container">
                          <div className="row">
                            
                              <div className="col-12 iplwidge-page">
                                <div class="iplwidge-page-mob-nav">
                                    <Tabs
                                      id="controlled-tab-example"
                                      activeKey={key}
                                      onSelect={(k) => setKey(k)}
                                      className="mb-3"
                                    >
                                      <Tab eventKey="home" title="Match Center">
                                        <div id="whereUwantToPutOnlyIdmatch_center"></div>
                                      </Tab>
                                      <Tab eventKey="profile" title="Points Table">
                                        <div id="whereUwantToPutOnlyIdcompetetion_standings"></div>
                                      </Tab>
                                      <Tab eventKey="contact" title="Most Runs">
                                        <div id="whereUwantToPutOnlyIdseries_top_pages"></div>
                                      </Tab>
                                      <Tab eventKey="TopWicket" title="Top Wicket Takers">
                                        <div id="whereUwantToPutOnlyIdTopWickets"></div>
                                      </Tab>
                                    </Tabs>
                                
                                </div>
                                
                                
                              </div>
                          </div>
                      </div>
                  </main>
                <Footer/>
            </div>
            <a href="javascript:void(0)" id="back-to-top">
                <i className="fas fa-angle-double-up"></i>
            </a>
        </div>    
    </>
  )
};

export default WidgeDetails;


