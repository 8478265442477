import React, { useEffect, useRef } from "react";

const WidgetHome = () => {
  const scriptRef = useRef(null);

  useEffect(() => {
    // Remove any existing script to avoid duplication
    if (scriptRef.current) {
      document.body.removeChild(scriptRef.current);
      scriptRef.current = null;
    }

    // Ensure Entity_sport is declared BEFORE loading the script
    window.Entity_sport = {
      code: "4654436544",
      field: "entity_cricket",
      widget_type: "content_type",
      widget: "competetion_slider",
      id: 129413,
      more_one: "",
      widget_size: "large",
      where_to: "whereUwantToPutOnlyIdcompetetion_slider",
      base_path: "https://dashboard.entitysport.com/widget/dashboard/example/",
      links: 0,
      color_type: "light",
      choosed_color: "",
      choosed_preset: "",
    };

    // Function to modify the href attribute after script loads
    const modifyLinks = () => {
      document
        .querySelectorAll(".entity-cricket-match-row[data-mid]")
        .forEach((el) => {
          const mid = el.getAttribute("data-mid");
          if (mid) {
            el.setAttribute("href", `/ipl/${mid}`);
          }
        });
    };

    // Create and append the script dynamically
    const script = document.createElement("script");
    script.src = "https://dashboard.entitysport.com/widget/assets/js/widget.js";
    script.defer = true;
    script.onload = () => {
      setTimeout(modifyLinks, 1000); // Delay to ensure elements exist
    };
    document.body.appendChild(script);
    scriptRef.current = script;

    // Observe changes to dynamically modify elements
    const observer = new MutationObserver(modifyLinks);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      // Cleanup: Remove script and disconnect observer when component unmounts
      if (scriptRef.current) {
        document.body.removeChild(scriptRef.current);
        scriptRef.current = null;
      }
      observer.disconnect();
    };
  }, []);

  return <div id="whereUwantToPutOnlyIdcompetetion_slider"></div>;
};

export default WidgetHome;
