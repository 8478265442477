import React, { useState, useEffect } from 'react'
import { NavLink, useHistory, useParams} from 'react-router-dom'
import WidgetHome from './WidgetHome';

const Header =()=>{
    const locale = 'en';
    const [csss, setSccc] = useState(false)
    const {categoryId} = useParams()
    const [today, setDate] = React.useState(new Date()); // Save the current date to be able to trigger an update
    const year = new Date().getFullYear();
    const date = `${today.toLocaleDateString(locale, { month: 'long' })} ${today.getDate()}, ${year} \n\n`;
    const [search, setSearch] = useState();
    const [searchHead, SetSHead] = useState('template-search');
    const inputData=(event)=>{
        setSearch(event.target.value);
      }
      const history = useHistory();
      const onSubmit = (e) => {
        e.preventDefault()
        let path = `/search/` +search;
            SetSHead('template-search')
        history.push(path);
        // console.log(conFom)
      }
      useEffect(() => {

        setSccc(false)
      }, [categoryId])
    return (
        <>
        <header className="rt-header sticky-on">
            <div id="sticky-placeholder"></div>
            <div className="topbar topbar-style-1" id="topbar-wrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="meta-wrap">
                                <span className="rt-meta">
                                    <i className="far fa-calendar-alt icon"></i>
                                    <span className="currentDate">
                                        {date}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="rt-topbar-right">
                                <div className="social-wrap d-none d-xl-block">
                                    <ul className="rt-top-social">
                                        <li className="follow">Follow Us:</li>
                                        <li>
                                            <a href="https://www.facebook.com/iansnews.in/" target="_blank">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/ians_india" target="_blank">
                                            <i className="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/iansnews.in/" target="_blank">
                                            <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                        <li><a href="https://www.youtube.com/user/iansindia" target="_blank">
                                            <i className="fab fa-youtube"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-main header-main-style-1 navbar-wrap" id="navbar-wrap">
                <div className="container">
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="site-branding">
                               <a className="dark-logo" href="/">
                                    <img width="162" height="52" src="/assets/Images/logo-ianslive.png" alt="neeon" />
                                </a>
                               <a className="light-logo" href="/">
                                    <img width="162" height="52" src="/assets/Images/logo-ianslive.png" alt="neeon" />
                                </a>
                            </div>
                            <div className="main-menu">
                                <nav className="main-menu__nav">
                                    <ul>
                                        <li className=" active">
                                           <a className="animation" href="/">Home</a>
                                        </li>
                                        <li>
                                           <a className="animation" href="/category/national">National</a>
                                        </li>
                                        <li>
                                           <a className="animation" href="/category/sports">Sports</a>
                                            
                                        </li>
                                        <li>
                                           <a className="animation" href="/category/international">International </a>
                                        </li>
                                        <li>
                                           <a className="animation" href="/category/business">Business</a>
                                        </li>
                                        <li>
                                           <a className="animation" href="/category/entertainment">Entertainment</a>
                                        </li>
                                        <li>
                                           <a className="animation" href="/category/science-tech">Science &  Technology</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <ul className="header-action-items">
                                <li className="item" onClick={()=>{
                                                SetSHead('template-search open')}}>
                                   <a href="#"><i className="fas fa-search"></i></a>
                                </li>
                            </ul>
                            <div id="template-search" className={searchHead}>
                                <button type="button" className="close" onClick={()=>{
                                                SetSHead('template-search')}}>×</button>
                                <form className="search-form" onSubmit={onSubmit}>
                                    
                                    <input type="search" onChange={inputData} name="search" value={search} placeholder="Search" />
                                    <button type="submit" className="search-btn btn-ghost style-1">
                                    <i className="flaticon-search"></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div className="rt-mobile-header">
            <div id="mobile-sticky-placeholder"></div>
            <div className="mobile-menu-bar-wrap" id="mobile-menu-bar-wrap">
                <div className="mobile-menu-bar">
                    <div className="logo">
                   <a href="/">
                        <img src="/assets/Images/logo-ianslive.png" alt="neeon" width="162" height="52" />
                    </a>
                    </div>
                    <span className="sidebarBtn" onClick={()=>{setSccc(!csss)}}>
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                    </span>
                </div>
                <div className="rt-slide-nav" style={{display: !csss ? 'none':'block'}}>
                    <div className="offscreen-navigation">
                        <nav className="menu-main-primary-container">
                            <ul className="menu">
                                <li className="active">
                                   <a className="animation" href="/">Home</a>
                                </li>
                                <li>
                                   <a className="animation" href="/category/national">National</a>
                                </li>
                                <li>
                                   <a className="animation" href="/category/sports">Sports</a>
                                </li>
                                <li>
                                   <a className="animation" href="/category/international">International </a>
                                </li>
                                <li>
                                   <a className="animation" href="/category/business">Business</a>
                                </li>
                                <li>
                                   <a className="animation" href="/category/entertainment">Entertainment</a>
                                </li>
                                <li>
                                   <a className="animation" href="/category/science-tech">Science &  Technology</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <WidgetHome/>
        </>
    )
}
export default Header;